import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [  
  //navigator.userAgent.includes('Windows') ? 
  // {path: '', loadChildren: ()=>import('../pages/home-desktop/home-desktop.module').then(m=>m.HomeDesktopModule), pathMatch: 'full'}
  // : {path: '', loadChildren: ()=>import('../pages/home-mobile/home-mobile.module').then(m=>m.HomeMobileModule), pathMatch: 'full'}

  {path: '', loadChildren: ()=>import('../pages/home-mobile/home-mobile.module').then(m=>m.HomeMobileModule), pathMatch: 'full'}
];



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
